
import axios from 'axios'
import { defineComponent, ref, computed } from 'vue'
import { ExclamationCircleOutlined, CheckCircleOutlined } from '@ant-design/icons-vue'
import { FileItem, FileInfo } from '@/API/types';
import { message } from 'ant-design-vue';
import { Modal } from 'ant-design-vue'
import downloadFile from '@/utils/rv/downloadFile'
import { useStore } from 'vuex'
export default defineComponent({
    components: {
        ExclamationCircleOutlined,
        CheckCircleOutlined
    },
    emits: ["close-upload-visible","change-table"],
	props: {
		isUploadVisible: {
			type: Boolean,
			required: true,
			default: false
		},
        uploadTitle: {
            type: String,
            required: true,
            default: ''
        },
        entity: {
            type: String,
            required: true,
            default: ''
        }
	},
    setup(props, context) {
        const { state } = useStore()

        const isBeforeUpload = ref<boolean>(true)
        // 进度条
        const isShowUploadBtn = ref<boolean>(true)
        const curUploadFile = ref<FileItem>();
        const curPercent = ref<number>(0)
        const uploadFileSize = ref<string>('')
        const completeTips = ref<boolean>(false)
        // 上传功能出错后下载模态窗口
        const loading = ref<boolean>(false);
        const downloadModalVisible = ref<boolean>(false)
        const downloadTipsText = ref<string>('')
        // 导入控制按钮
        const isImport = ref<boolean>(false)
        // 文件上传
        const isClickAbled = ref<boolean>(true)
        const uploadComplete = ref<boolean>(true);
        const formData = new FormData();
        const emptyVisible = ref<boolean>(false)
        const emptyTipsText = ref<string>('')
        // 获取标题内容
        const title = computed(() => props.uploadTitle)
        // // 设置抽屉显示状态
        // const modal = props.isUploadVisible;
        const close = () => {
            context.emit('close-upload-visible', { isUploadVisible: false })
        }
        // 获取upload round 列表
        // const uploadListParams = {afcLcType: `${roleVal}`, type: 'RT'}
        // const params = uploadListParams
        let fileName: string | undefined
        // 上传前
        const beforeUpload = (file: FileItem) => {
            fileName = file.name
            const isXlsx = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            if (!isXlsx) {
                message.error('Only xlsx format files can be uploaded!')
            }
            const isLt5M = file.size / 1024 / 1024 < state.payment.maxFileSize
            if (!isLt5M) {
                message.error(`The file size should not be larger than ${state.payment.maxFileSize}MB!`);
            }
            return isXlsx && isLt5M
        }
        const uploadRequest = (source: FileInfo) => {
            if (formData.has('file')){
                formData.delete('file')
            }
            formData.append('file', source.file as unknown as Blob);
            curUploadFile.value = source.file;
            uploadFileSize.value = source.file.size / 1024 > 1024 ? `${(source.file.size / 1024 / 1024).toFixed(1)} MB` : `${Math.floor(source.file.size / 1024)} KB`
            isBeforeUpload.value = false
        }
        let fileId = ''
        // 上传文件
        const uploadFile = () => {
            isClickAbled.value = false
            const params = {
                file: formData,
                entity: props.entity
            }
            axios({
                method: 'post',
                data: formData,
                url: '/ptapi/vehicle/uploadFile',
                params: params
            }).then(res => {
                isShowUploadBtn.value = false
                curPercent.value = 100
                completeTips.value = true
                isClickAbled.value = true

                // 9011：文件为空 50073：模板不对
                if (res.data.code !== '0' && res.data.code !== '9011' && res.data.code !== '50073') {
                    // 显示模态窗口
                    downloadModalVisible.value = true
                    downloadTipsText.value = res.data.message
                    // 存data里的信息
                    if (res.data.data !== '') fileId = res.data.data
                } else if (res.data.code === '9011' || res.data.code === '50073') {
                    emptyVisible.value = true
                    emptyTipsText.value = res.data.message
                } else {
                    message.success('File check successfully!')
                    isImport.value = true
                }
            })
        }
        // 上传功能出错后下载模态窗口
        const handleDownload = () =>{
            loading.value = true;
            // 调下载错误文件接口
            const id = fileId
            const params = {
                url: `/ptapi/downloadFile?fileId=${id}`,
                method: 'get',
                params: {},
                fileName: fileName ? fileName : `${title.value}.xlsx`
            }
            downloadFile(params).then(() => {
                loading.value = false;
                downloadModalVisible.value = false;
                emptyVisible.value = false;
            })
        }
        const handleCancel = () => {
            downloadModalVisible.value = false;
        };
        const showUploadHandle = () => {
            isBeforeUpload.value = true
            isShowUploadBtn.value = true
            curPercent.value = 0
            completeTips.value = false
            window.sessionStorage.removeItem('errorFileName');
            isImport.value = false
        }

        // 下载模板接口调用
        const downloadTemHandle = () => {
            const params = {
                url: '/ptapi/vehicle/downloadTemplate?entity='+props.entity,
                method: 'get',
                fileName: title.value + '.xlsx'
            }
            downloadFile(params)
        }
        // 导入接口调用
        const importFile = () => {
            const params = {
                file: formData,
                entity: props.entity
            }
            axios({
                method: 'post',
                data: formData,
                url: '/ptapi/vehicle/importFile',
                params: params
            }).then(res => {
                if (res.data.code !== '0') {
                    Modal.warning({
                        title: "Tips",
                        content: res.data.message,
                        centered: true
                    })
                } else {
                    message.success('File Upload successfully!')
                    context.emit('change-table');
                }
            })
        }
        return {
            downloadModalVisible,
            downloadTipsText,
            handleDownload,
            handleCancel,
            title,
            // modal,
            close,
            uploadFile,
            beforeUpload,
            // uploadListParams,
            downloadTemHandle,
            uploadComplete,
            importFile,
            isBeforeUpload,
            isShowUploadBtn,
            uploadRequest,
            curPercent,
            curUploadFile,
            uploadFileSize,
            isImport,
            showUploadHandle,
            completeTips,
            isClickAbled,
            emptyVisible,
            emptyTipsText,
            loading
        }
    },
})
