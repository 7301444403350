import { ref, reactive, computed } from "vue";
import * as API from "@/API/payment";
import downloadFile from "@/utils/rv/downloadFile";
import moment from "moment";
import { useRoute } from "vue-router";

type SearchParams = {
  model: string;
  materialNo: string;
  entity: string;
};

export interface SalesTableData {
  model: string;
  materialNo: string;
  modelName: string;
  effectiveDate: string;
}

const useVehicleNameSet = () => {
  const route = useRoute();
  const entityRef = ref();
  const searchParams = reactive<SearchParams>({
    model: "",
    materialNo: "",
    entity: route.query.entity as string,
  });
  const total = ref<number>(0);
  const pageNumber = ref<number>(0);
  const pageSize = ref<number>(10);
  const entityColName = ref<string>("");
  const columnsData = computed(() => {
    return [
      { title: "Model", dataIndex: "model" },
      { title: "Material No.", dataIndex: "materialNo" },
      { title: entityColName.value + " Model Name", dataIndex: "modelName" },
      {
        title: "生效日期",
        dataIndex: "effectiveDate",
        slots: { customRender: "effectiveDate" },
      },
    ];
  });
  const tableData = ref<SalesTableData[]>();
  const getTableData = () => {
    entityColName.value = searchParams.entity;
    const params = {
      entity: searchParams.entity,
      materialNo: searchParams.materialNo,
      model: searchParams.model,
      pageNumber: pageNumber.value,
      pageSize: pageSize.value,
    };
    API.getVehicleList(params).then((data) => {
      tableData.value = data.content;
      total.value = data.totalElements;
    });
  };
  const doSearch = () => {
    console.log("search按钮");
    searchParams.model = searchParams.model ? searchParams.model : "";
    searchParams.materialNo = searchParams.materialNo
      ? searchParams.materialNo
      : "";
    searchParams.entity = searchParams.entity ? searchParams.entity : "";
    pageNumber.value = 1;
    getTableData();
  };
  const doReset = () => {
    Object.assign(searchParams, {
      model: "",
      materialNo: "",
    });
    entityRef.value.reset();
    tableData.value = [];
    doSearch();
  };
  const pageChange = (page: number, size: number) => {
    pageNumber.value = page > 0 ? page : 1;
    pageSize.value = size;
    getTableData();
  };
  const sizeChange = (page: number, size: number) => {
    pageNumber.value = page > 0 ? page : 1;
    pageSize.value = size;
    getTableData();
  };
  const isUploadVisible = ref<boolean>(false);
  const exportClick = () => {
    const params = {
      url: "/ptapi/vehicle/export",
      method: "get",
      params: {
        entity: searchParams.entity,
      },
      fileName:
        searchParams.entity + "_" + moment().format("YYYYMMDD") + ".xlsx",
    };
    downloadFile(params);
  };
  const uploadClick = () => {
    isUploadVisible.value = true;
  };
  const closeUpload = (param: { isUploadVisible: boolean }) => {
    isUploadVisible.value = param.isUploadVisible;
  };
  const refreshTable = () => {
    closeUpload({ isUploadVisible: false });
    getTableData();
  };
  return {
    searchParams,
    columnsData,
    tableData,
    doSearch,
    doReset,
    pageChange,
    sizeChange,
    pageSize,
    pageNumber,
    total,
    isUploadVisible,
    exportClick,
    uploadClick,
    closeUpload,
    refreshTable,
    entityRef,
  };
};
export default useVehicleNameSet;
