
import { defineComponent } from "vue";
import useVehicleNameSet from "@/hooks/payment/EntitySiPayout/useVehicleNameSet";
import { ColumnProps } from "ant-design-vue/es/table/interface";
type Key = ColumnProps["key"];
const tableWidth = window.innerWidth;
const tableHeight = window.innerHeight - 402;
import UploadDetail from "@/views/Payment/EntitySiPayout/Vehicle/uploadDetail.vue";
// import Pagination from "@/components/Pagination.vue";
import TableWrapper from "@/components/TableWrapper.vue";
import { useStore } from "vuex";
import EntitySelector from "@/views/Payment/components/EntitySelector.vue";
export default defineComponent({
  name: "DealerVendorCodeSet",
  components: {
    UploadDetail,
    // Pagination,
    TableWrapper,
    // EntitySelector,
  },
  props: {},
  setup() {
    const store = useStore();
    const {
      searchParams,
      doReset,
      columnsData,
      tableData,
      doSearch,
      pageChange,
      sizeChange,
      pageSize,
      pageNumber,
      total,
      isUploadVisible,
      exportClick,
      uploadClick,
      closeUpload,
      refreshTable,
    } = useVehicleNameSet();
    doSearch();
    const handlePageChange = (page: number, pageSize: number) => {
      pageChange(page, pageSize);
    };
    const handleSizeChange = (page: number, pageSize: number) => {
      sizeChange(page, pageSize);
    };
    return {
      store,
      searchParams,
      doSearch,
      doReset,
      tableWidth,
      tableHeight,
      columnsData,
      tableData,
      pageSize,
      pageNumber,
      total,
      handlePageChange,
      handleSizeChange,
      isUploadVisible,
      exportClick,
      uploadClick,
      closeUpload,
      refreshTable,
    };
  },
});
